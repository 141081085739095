import React from 'react';

const Banner = ({
  ...props
}) => {

  // const [bannerOpen, setBannerOpen] = useState(true);

  return (
    <>
      {}
    </>
  );
}

export default Banner;