import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Competitive advantage in the connected world.',
    paragraph: 'With a broad skillset rooted in the foundational doctrine of security, we stand ready to tackle any problem and give you the tools for exceptional transformational growth.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">

        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
             <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                    <i className="fa fa-shield-halved" style={{marginTop:'34%', marginLeft:'34%', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Systems Auditing
                    </h4>
                  <p className="m-0 text-sm">
                    Network and device security scans to identify vulnerabilities that may be exposing you to potential threats.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                  <i className="fa fa-database" style={{marginTop:'33%', marginLeft:'23px', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Database Management
                    </h4>
                  <p className="m-0 text-sm">
                    Gain peace of mind knowing your data and assets are safely handled with best-in-class security provisions and practices.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                    <i className="fa fa-screwdriver-wrench" style={{marginTop:'34%', marginLeft:'34%', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Solutions Development
                    </h4>
                  <p className="m-0 text-sm">
                    Software and product development to satisfy all manner of requirements.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                    <i className="fa fa-fingerprint" style={{marginTop:'34%', marginLeft:'34%', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Personal Security Monitoring
                    </h4>
                  <p className="m-0 text-sm">
                    Understanding threats posed to you as an individual can lead to better responses and practices to mitigate against them.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                    <i className="fa fa-satellite-dish" style={{marginTop:'34%', marginLeft:'34%', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Secure Infrastructure and Platform Hosting
                    </h4>
                  <p className="m-0 text-sm">
                    From data hosting to communications solutions, our hosting options will provide the security and speed to handle all of your requirements.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16" style={{width:'64px', height:'64px'}}>
                    <i className="fa fa-people-group" style={{marginTop:'34%', marginLeft:'32%', color:'white'}}></i>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Technical Consultancy and Training
                    </h4>
                  <p className="m-0 text-sm">
                    Integrating legacy systems with brand new products or overcoming problems when 
                    transitioning to new processes. We can help guide you on the path to continued excellence.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;