import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
import Logo from '../layout/partials/Logo';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Disruptive Innovation. Secure Implementation.',
    paragraph: 'Connectivity drives modern progress. Seek solutions that will drive you far beyond your competition.'
   };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <Logo className="center-content"/>
          <br/>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Focus on the details
                  </div>
                <h3 className="mt-0 mb-12">
                  Think
                  </h3>
                <p className="m-0">
                  Small issues cause friction to processes and efficiency over time.
                  If it's big enough to notice, it's big enough to cause a problem.
                  We offer services to work with you to identify smaller issues and eliminate them before they 
                  have an opportunity to grow into larger process blockers, as you and your company scale.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{filter:`brightness(100%) blur(3px)`}}>
                <Image
                  src={require('./../../assets/images/feature-image-1.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  generic is not enough
                  </div>
                <h3 className="mt-0 mb-12">
                  Plan
                  </h3>
                <p className="m-0">
                  Your company is unique, that's what drives your success. The solutions we provide are 
                  a reflection of your individuality and a tailored experience to ensure that the end result exceeds
                  your expectations.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{filter:`brightness(50%) blur(3px)`}}>
                <Image
                  src={require('./../../assets/images/feature-image-2.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Services that just work
                  </div>
                <h3 className="mt-0 mb-12">
                  Execute
                  </h3>
                <p className="m-0">
                We strive for efficiency of execution and minimising impact on business continuity.
                Our services will enable your business to evolve to face the problems of the future, 
                while effortlessy handling the obstacles of today.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{filter:`brightness(50%) blur(3px)`}}>
                <Image
                  src={require('./../../assets/images/feature-image-3.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;