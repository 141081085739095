import React, { useState } from 'react';
import Modal from 'react-modal';

const PrivacyModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };


  return (
    <div>
      <p style={{cursor: 'pointer' }} onClick={openModal}>Privacy Notice</p>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Privacy Notice"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(5px)',
          },
          content: {
            // backgroundColor: '#f83800',
            backgroundColor: '#151719',
            padding: '40px',
            borderRadius: '10px',
            maxWidth: '800px',
            margin: 'auto',
          },
        }}
      >
        <h2>Rift Horizon Privacy Policy</h2>
        <hr style={{ color: '#f83800', backgroundColor: '#f83800', height: '5px', border: 'none', margin: '10px 0' }} />
        <h6>Purpose of this notice</h6>
        <p style={{ fontSize: '0.8em' }}>
        This Privacy Policy ("Policy") outlines the commitment of Rift Horizon Limited ("the Company") to protecting the privacy and personal data of individuals in accordance with the General Data Protection Regulation (GDPR), Data Protection Act 1998 or 2018 (DPA 1998/2018), and other relevant data protection laws. This Privacy Policy explains how we collect, use, disclose, and protect your personal information.
        </p>

      <p style={{ fontSize: '0.8em' }}>
        Please read the following carefully to understand our practices regarding your personal data and how we will treat it.
      </p>
      <p style={{ fontSize: '0.8em' }}>

        We may need to make changes to our Privacy Policy; any changes will be reflected on our website. If there are important changes, such as changes to where your personal data will be processed; we will contact you to let you know.
      </p>
      <br/>
      <h6>Collected Information</h6>
      <p style={{ fontSize: '0.8em' }}>
      We may collect and process the following types of personal data:
      <ul>
        <li>
        Personal details (such as your name and/or address, phone numbers, email addresses)
        </li>
        <li>
        Documentary data (such as passport, driver licence, records of electronic identity searches)
        </li>
        <li>
        Information on your children. In these cases, we will collect and use only the Information required to identify the child (such as their name, age, gender)
        </li>
        <li>
        Information that is automatically collected (such as via cookies and log data when you visit one of our websites or use our online services)
        </li>
        <li>
        Information that is available online
        </li>
        <li>
        Visual images collected via physical video recording equipment
        </li>
        <li>
        Video and sound recordings of online meetings or calls
        </li>
        <li>
        Details of contact we have had with you in relation to the provision, or the proposed provision, of our services
        </li>
        <li>
        Details of any services you have received from us
        </li>
        <li>
        Correspondence and communications with you
        </li>
        <li>
        Information about any complaints and enquiries you make to us
        </li>
        <li>
        Information from research, surveys, and marketing activities
        </li>
        <li>
        Information and data we receive from other sources, such as publicly available information
        </li>
        <li>
        Information provided by your employer or our clients or third parties.
        </li>
      </ul>
      </p>
          <br/>
      <h6>How we use your data</h6>
      <p style={{ fontSize: '0.8em' }}>
      We may use your personal data for the following purposes:
      <ul>
      <li>
      Purposes necessary for the performance of our contract with you or your employer or our clients and to comply with our legal obligations.
        </li>
        <li>
        Performance of our contract with our clients. This may include processing your personal data where you are an employee, subcontractor, supplier or customer of our client.
        </li>
        <li>
        Legitimate business interests provided that those interests do not override any of your own interests, rights and freedoms which require the protection of personal data. This includes processing for marketing, business development, statistical and management purposes.
        </li>
        <li>
        Certain additional purposes with your consent, and in these limited circumstances where your consent is required for the processing of your personal data then you have the right to withdraw your consent to processing for such specific purposes.
        </li>
        <li>
        We may process your personal data for more than one lawful basis depending on the specific purpose for which we are using your data.
        </li>
        <li>
        Carry out our obligations arising from any agreements entered into between you or your employer or our clients and us (which will most usually be for the provision of our services).
        </li>
        <li>
        Carry out our obligations arising from any agreements entered into between our clients and us (which will most usually be for the provision of our services) where you may be a subcontractor, supplier or customer of our client.
        </li>
        <li>
        Provide you with information related to our services and our events and activities that you request from us or which we feel may interest you, provided you have consented to be contacted for such purposes (you may opt out at any time from these communications by clicking the unsubscribe link at the bottom of any email).
        </li>
        <li>
        Seek your thoughts and opinions on the services we provide or notify you about any changes to our services.
        </li>
      </ul>
      
    </p>
    <p style={{ fontSize: '0.8em' }}>
    In some circumstances we may anonymise or pseudo-anonymise the personal data so that it can no longer be associated with you, in which case we may use it without further notice to you.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      If you refuse to provide us with certain information when requested, we may not be able to perform the contract we have entered into with you. Alternatively, we may be unable to comply with our legal or regulatory obligations.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      We may also process your personal data without your knowledge or consent, in accordance with this notice, where we are legally required or permitted to do so.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      Should it be necessary to use your personal data for a new purpose, we will notify you and communicate the legal basis which allows us to do so before starting any new processing.      </p>

      <br/>
      <h6>Data Disclosure</h6>
      <p style={{ fontSize: '0.8em' }}>
      We will share your personal data with third parties where we are required by law, where it is necessary to administer the relationship between us or where we have another legitimate interest in doing so.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      “Third parties” includes third-party service providers. The following activities are carried out by third-party service providers: IT and cloud services, provision of staff and resources, professional advisory services, quality control reviews, insurance services, administration services, marketing services and banking services.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      All of our third-party service providers are required to take commercially reasonable and appropriate security measures to protect your personal data. We only permit our third-party service providers to process your personal data for specified purposes and in accordance with our instructions.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      We may share your personal data with other third parties, for example in the context of the possible sale or restructuring of the business. We may also need to share your personal data with a regulator or other authority or to otherwise comply with the law.
      </p>
      
      
      <br/>
      <h6>Data Retention</h6>
      <p style={{ fontSize: '0.8em' }}>
      We will keep your personal information while you are a client or as long as required to meet our legal or regulatory obligations. We may keep it longer if we cannot delete it for legal, regulatory or technical reasons.
      </p>
      
      <p style={{ fontSize: '0.8em' }}>
      When assessing what retention period is appropriate for your personal data, we take into consideration:
      <ul>
      <li>
      Requirements of our business and the services provided
        </li>
        <li>
        Statutory or legal obligations
        </li>
        <li>
        Purposes for which we originally collected the personal data
        </li>
        <li>
        Lawful grounds on which we based our processing
        </li>
        <li>
        Types of personal data we have collected
        </li>
        <li>
        Amount and categories of your personal data
        </li>
        <li>
        Whether the purpose of the processing could reasonably be fulfilled by other means.
        </li>
      </ul>
      </p>
      
      <br/>
      <h6>Data Security</h6>
      <p style={{ fontSize: '0.8em' }}>
      We have put in place commercially reasonable and appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We limit access to your personal data to those employees, agents, contractors and other third parties who have legitimate business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.      </p>
      
      <br/>
      <h6>Rights of access, correction, erasure and restriction</h6>      
      <p style={{ fontSize: '0.8em' }}>
      Under certain circumstances, by law you have the right to:
      <ul>
      <li>
      Request access to your personal data. This enables you to receive details of the personal data we hold about you and to check that we are processing it lawfully.
        </li>
        <li>
        Request correction of the personal data that we hold about you.
        </li>
        <li>
        Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to processing.
        </li>
        <li>
        Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this basis. You also have the right to object where we are processing your personal information for direct marketing purposes.
        </li>
        <li>
        Request the restriction of processing of your personal data. This enables you to ask us to suspend the processing of personal data about you, for example if you want us to establish its accuracy or the reason for processing it.
        </li>
        <li>
        Request the transfer of your personal data to you or another data controller if the processing is based on consent, carried out by automated means and this is technically feasible.
        </li>
      </ul>
      </p>
      <p style={{ fontSize: '0.8em' }}>
      In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal data for a specific purpose (for example, in relation to direct marketing that you have indicated you would like to receive from us), you have the right to withdraw your consent for that specific processing at any time. To withdraw your consent please contact us.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      Once we have received notification that you have withdrawn your consent, we will no longer process your personal information (personal data) for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law.
      </p>
      <p style={{ fontSize: '0.8em' }}>
      We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We try to respond to all legitimate requests within one month. Occasionally it may take longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
      </p>

      <br/>
      <h6>Changes to this Privacy Policy</h6>      
      <p style={{ fontSize: '0.8em' }}>
      We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on our website.
      </p>

      <br/>
      <h6>Contact Us</h6>      
      <p style={{ fontSize: '0.8em' }}>
      If you have any questions or concerns about this Privacy Policy or your data protection rights, please contact us at:
      </p>
      <p style={{ fontSize: '0.8em' }}>
      Rift Horizon Limited<br/>
      Data Protection Officer<br/>
      privacy@rifthorizon.co
      </p>
            
      </Modal>
    </div>
  );
};

export default PrivacyModal;
